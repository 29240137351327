import { createRouter, createWebHistory } from 'vue-router'
import HomePage from "./pages/HomePage.vue";
import AuthPage from "./pages/AuthPage.vue";
import SettingsPage from "./pages/SettingsPage.vue";
import PickTeamPage from "./pages/PickTeamPage.vue";
import PickSquadPage from "./pages/PickSquadPage.vue";
import PlayerPage from "./pages/PlayerPage.vue";
import PlayersPage from "./pages/PlayersPage.vue";
import MyPointsPage from "./pages/MyPointsPage.vue";
import FeedbackPage from "./pages/FeedbackPage.vue";
import LeaderboardPage from "./pages/LeaderboardPage.vue";
import FixturePage from "./pages/FixturePage.vue";
import MiniLeaguesPage from "./pages/MiniLeaguesPage.vue";
import MiniLeaguePage from "./pages/MiniLeaguePage.vue";
import ResetPasswordPage from "./pages/ResetPasswordPage.vue";
import AdminPage from "./pages/AdminPage.vue";
import PredictionsPage from "./pages/PredictionsPage.vue";
import SweepStakePage from "./pages/SweepStakePage.vue";
import TwentyOnePage from "./pages/TwentyOnePage.vue";
import TwentyOneIndexPage from "./pages/TwentyOneIndexPage.vue";
import TwentyOneFixturePage from "./pages/TwentyOneFixturePage.vue";
import SeasonPage from "./pages/SeasonPage.vue";
import SeasonHomePage from "./pages/SeasonHomePage.vue";
import AdminPlayerValuesPage from "./pages/AdminPlayerValuesPage.vue";
import AdminInactivePage from "./pages/AdminInactivePage.vue";
import AdminCreatePlayerPage from "./pages/AdminCreatePlayerPage.vue";
import TwentyOneUserPage from "./pages/TwentyOneUserPage.vue";
import PlayerHistoriesPage from "@/pages/PlayerHistoriesPage.vue";
import GodPage from "@/pages/GodPage.vue";
import GodDashboardPage from "@/pages/GodDashboardPage.vue";
import GodCreateSeasonPage from "@/pages/GodCreateSeasonPage.vue";
import GodEditSeasonPage from "@/pages/GodEditSeasonPage.vue";
import AdminPlayerPage from "@/pages/AdminPlayerPage.vue";
import AdminSeasonPage from "@/pages/AdminSeasonPage.vue";
import ModPage from "@/pages/ModPage.vue";
import ModSeasonPage from "@/pages/ModSeasonPage.vue";
import ModPlayerPage from "@/pages/ModPlayerPage.vue";
import ModTransfersPage from "@/pages/ModTransfersPage.vue";
import GodTransfersPage from "@/pages/ModTransfersPage.vue";
import MiniLeagueEditPage from "@/pages/MiniLeagueEditPage.vue";
import MiniLeagueHomePage from "@/pages/MiniLeagueHomePage.vue";
import AuthEntriesPage from "@/pages/AuthEntriesPage.vue";
import PlatformLinksPage from "@/pages/PlatformLinksPage.vue";
import ModTeamsPage from "@/pages/ModTeamsPage.vue";
import ModGameweeksPage from "@/pages/ModGameweeksPage.vue";
import ModFixturesPage from "@/pages/ModFixturesPage.vue";
import ModFixturePage from "@/pages/ModFixturePage.vue";
import ModCreateGameweekPage from "@/pages/ModCreateGameweekPage.vue";
import ModDashboardPage from "@/pages/ModDashboardPage.vue";

/*
import LeaguesPage from "./vue/Pages/LeaguesPage.vue";
import AuthPage from "./vue/Pages/AuthPage.vue";
import LeaguesCreatePage from "./vue/Pages/LeaguesCreatePage.vue";
import LeaguesJoinPage from "./vue/Pages/LeaguesJoinPage.vue";
import LeaguesShowPage from "./vue/Pages/LeaguesShowPage.vue";
import LeaguesEntryPage from "./vue/Pages/LeaguesEntryPage.vue";
import SeasonPage from "./vue/Pages/SeasonPage.vue";
*/

const routes = [
    { path: '/', component: HomePage, name: 'home', meta: { allowGuest: true } },
    { path: '/entries', component: AuthEntriesPage, name: 'auth-entries' },
    { path: '/auth', component: AuthPage, name: 'auth', meta: { allowGuest: true } },
    { path: '/feedback', component: FeedbackPage, name: 'feedback', meta: { allowGuest: true } },
    { path: '/reset-password/:token', component: ResetPasswordPage, name: 'reset-password', props:true, meta: { allowGuest: true } },
    { path: '/settings', component: SettingsPage, name: 'settings' },
    { path: '/settings', component: SettingsPage, name: 'settings' },
    { path: '/platform-links/:seasonId?/:modelType?/:modelId?', component: PlatformLinksPage, name: 'platform-links' },
    { path: '/god', component: GodPage, props: true, children: [
            { path: '', component: GodDashboardPage, name: 'god', props: true },
            { path: 'create-season', component: GodCreateSeasonPage, name: 'god-create-season', props: true },
            { path: 'edit-season/:seasonId', component: GodEditSeasonPage, name: 'god-edit-season', props: true },
        ]},
    { path: '/mod', component: GodPage, props: true, children: [
            { path: '', component: ModDashboardPage, name: 'mod', props: true },
            { path: 'transfers', component: GodTransfersPage, name: 'mod-transfers', props: true },
        ]},
    { path: '/season/:seasonId', component: SeasonPage, props: true, children: [
        { path: '', component: SeasonHomePage, name: 'season', meta: { allowGuest: true } },
        { path: 'settings', component: SettingsPage, name: 'season-settings', props: true },
        { path: 'mod', component: ModPage, props: true, children: [
            { path: '', component: ModSeasonPage, name: 'mod-season', props: true },
            { path: 'create-player', component: AdminCreatePlayerPage, name: 'mod-create-player', props: true },
            { path: 'player/:playerId', component: ModPlayerPage, name: 'mod-player', props: true },
            { path: 'transfers/:status?', component: ModTransfersPage, name: 'mod-season-transfers', props: true },
            { path: 'teams', component: ModTeamsPage, name: 'mod-teams', props: true },
            { path: 'gameweeks', component: ModGameweeksPage, name: 'mod-gameweeks', props: true },
            { path: 'gameweeks/create', component: ModCreateGameweekPage, name: 'mod-create-gameweek', props: true },
            { path: 'fixtures', component: ModFixturesPage, name: 'mod-fixtures', props: true },
            { path: 'fixtures/:fixtureId', component: ModFixturePage, name: 'mod-fixture', props: true }
        ]},
        { path: 'admin', component: AdminPage, props: true, children: [
            { path: '', component: AdminSeasonPage, name: 'admin', props: true },
            { path: 'values', component: AdminPlayerValuesPage, name: 'admin-values', props: true },
            { path: 'inactive', component: AdminInactivePage, name: 'admin-inactive', props: true },
            { path: 'create-player', component: AdminCreatePlayerPage, name: 'admin-create-player', props: true },
            { path: 'player/:playerId', component: AdminPlayerPage, name: 'admin-player', props: true }
        ]},
        { path: 'leaderboard', component: LeaderboardPage, name: 'leaderboard', meta: { allowGuest: true } },
        { path: 'fixture/:fixtureId', component: FixturePage, name: 'fixture', props: true, meta: { allowGuest: true } },
        { path: 'players', component: PlayersPage, name: 'players', props: true, meta: { allowGuest: true } },
        { path: 'players/history', component: PlayerHistoriesPage, name: 'player-histories', props: true, meta: { allowGuest: true } },
        { path: 'player/:playerId', component: PlayerPage, name: 'player', props: true, meta: { allowGuest: true } },
        { path: 'mini-leagues', component: MiniLeaguesPage, name: 'mini-leagues' },
        { path: 'mini-leagues/:code', component: MiniLeaguePage, meta: { allowGuest: true }, props: true, children: [
            { path: '', component: MiniLeagueHomePage, name: 'mini-league', props: true },
            { path: 'edit', component: MiniLeagueEditPage, name: 'mini-league-edit', meta: { allowGuest: false }, props: true },
        ]},
        { path: 'points/:entryId?', component: MyPointsPage, name: 'points', props: true },
        { path: 'team', component: PickTeamPage, name: 'team' },
        { path: 'transfers', component: PickSquadPage, name: 'transfers' },
        { path: 'predictions', component: PredictionsPage, name: 'predictions' },
        { path: 'mini-games/twenty-one', component: TwentyOnePage, meta: { allowGuest: true }, props: true, children: [
            { path: '', component: TwentyOneIndexPage, name: 'twenty-one', props: true },
            { path: 'user/:userId', component: TwentyOneUserPage, name: 'twenty-one-user', props: true },
            { path: 'fixture/:fixtureId', component: TwentyOneFixturePage, name: 'twenty-one-fixture', props: true },
        ]}
    ]},
    { path: '/sweep-stake', component: SweepStakePage, name: 'sweep-stake' }, ,
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
})

export default router
